<template>
  <div class="overall">
    <div class="content">
      <el-row>
        <el-col>
          <el-button type="primary" @click=handleAdd()>新增</el-button>
        </el-col>
      </el-row>

      <!-- table -->
      <div class="tableBox">
            <el-table
                :data="tableData"
                v-loading="tableLoading"
                max-height="650"
                border
                style="width: 100%">
                <el-table-column
                    prop="code" 
                    label="用户编号">
                </el-table-column>
                <el-table-column
                    prop="user" 
                    label="用户">
                </el-table-column>
                <el-table-column
                    prop="phone" 
                    label="手机号">
                </el-table-column>
                <el-table-column
                    prop="roleName" 
                    label="角色">
                </el-table-column>
                <!-- <el-table-column
                    label="操作"
                    width="200">
                    <template slot-scope="scope">
                        <el-button
                        @click.native.prevent="handleEdit(scope.$index, scope.row)"
                        type="primary">
                        编辑
                        </el-button>
                        <el-button
                        @click.native.prevent="handleDelete(scope.$index, scope.row)"
                        type="danger">
                        删除
                        </el-button>
                    </template>
                </el-table-column> -->
            </el-table>
        </div>
        <!-- 新增以及编辑弹框 -->
        <el-dialog
            :title="dialogTitle"
            :visible.sync="updateVisible"
            :before-close="beforeClose"
            width="30%">
            <el-form 
                ref="form"
                :model="form"
                :rules="rules"
                label-width="90px">
                <el-form-item label="用户名" prop="user">
                    <el-input placeholder="请输入标签内容" v-model.trim="form.user"></el-input>
                </el-form-item>
                <el-form-item label="手机号" prop="phone">
                    <el-input placeholder="请输入标签内容" maxlength="11" v-model.trim="form.phone"></el-input>
                </el-form-item>
                <el-form-item label="角色" prop="roleId">
                  <el-select v-model="form.roleId" placeholder="请选择" style="width: 100%">
                    <el-option
                      v-for="item in roleList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="beforeClose()">取 消</el-button>
                <el-button type="primary" @click="handleConfirm()">确 定</el-button>
            </span>
        </el-dialog>
    </div>
  </div>
</template>

<script>
  import {getList,remove,update} from '../../../api/user';
  import{roleList} from '../../../api/role'
export default {
  data(){
    return{
      user:'',
      code:'',
      phone:'',
      roleName:'',
      id:'',
      roleId:'',
      tableData:[],
      roleList:[],
      tableLoading: true,
      updateVisible: false,
      dialogTitle: '',
      form:{
        roleId:"",
        phone:'',
        user:'',
      },
      // 前台验证
      rules:{
        user: [
            { required: true, message: '用户名不能为空', trigger: 'blur' },
        ],
        phone: [
            { required: true, message: '手机号不能为空', trigger: 'blur' },
        ],
        roleId: [
            { required: true, message: '角色不能为空', trigger: 'blur' },
        ],
    }
  }
},
  mounted(){
    this.userList();
  },
  methods:{
        //列表接口
        // async 声明了一个 异步函数，这个异步函数体内有一行 await 语句，它告示了该行为同步执行。
        async userList(){
          
          try {
            // 显示加载中动画
            this.tableLoading = true;
            // 访问后台接口
            let data= await getList();
            // 表格数据
            this.tableData = data;
          } catch (error) {
            console.log("error",error);
          }
          // 查询完毕取消加载中动画
          this.tableLoading = false;
        },

        //新增/编辑接口
        async userUpdate(){
          
          try {
            const params={
              ...this.form
            }
            // if (this.dialogTitle ==="编辑") {
            //   params.id = this.itemId
            // }
            await update(params);
            this.$message({
                      showClose: true,
                      type: "success",
                      message: "操作成功!"
                });
            this.userList();
          } catch (error) {
            console.log("error",error);
          }
          //表单数据置空，并移除校验
          this.$refs['form'].resetFields();
          this.updateVisible = false;
        },

        //新增按钮
        handleConfirm(){
          this.$refs['form'].validate((valid) => {
            if(valid){
              this.userUpdate();
            }else{
              console.log("error submit!")
              return false;
            }
          });
        },

          // 添加按钮
        async handleAdd(){
            try {
              // 访问后台接口
              let data= await roleList();
              // 表格数据
              this.roleList = data;
              this.dialogTitle ="新增";
              this.updateVisible =true;
            } catch (error) {
              console.log('error',error);
            }
          },

        // 编辑按钮
        handleEdit(index,rows){
          this.dialogTitle ="编辑";
          this.updateVisible =true;
          // 将选中这条数据的值赋值给编辑弹窗
          this.form = rows;
          this.itemId = rows.id;
        },

         //新增/编辑页弹出框取消
         beforeClose() {
            this.updateVisible = false;
            //表单数据置空，并移除校验
            this.$refs['form'].resetFields();
        },

         //删除
         handleDelete(index,row) {
            let id = row.id
           this.$confirm("此操作将永久删除该数据, 是否继续?", {
               confirmButtonText: "确定",
               cancelButtonText: "取消",
               type: "warning"
           }).then(() => {
               const params = {
                id
               };
               return remove(params);
           }).then(() => {
               this.userList();
               this.$message({
                    showClose: true,
                    type: "success",
                    message: "操作成功!"
               });
            }).catch(() => {
              this.$message({
              type: 'info',
              message: '已取消删除'
              });          
            });
       },

  }
}
  


</script>

<style lang="scss" scoped>

.overall {
    .content{
        .tableBox {
            padding-top: 20px;
        }
        .paginationBox {
            padding-top: 20px;
            text-align: center;
        }
    }
}

</style>